import * as React from "react";
import { useRef } from "react";
import { graphql } from "gatsby";
import { isBrowser, isNode, isWebWorker, isJsDom } from "browser-or-node";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { Button } from "react-bootstrap";
import Flippy, { FrontSide, BackSide } from "react-flippy";

import baby1 from "../images/einschlafbegleitung/einschlafbegleitung-schlafgut-schlafberatung-baby-1-min.webp";
import baby2 from "../images/einschlafbegleitung/einschlafbegleitung-schlafgut-schlafberatung-baby-2-min.webp";
import familie1 from "../images/einschlafbegleitung/einschlafbegleitung-schlafgut-schlafberatung-familie-1-min.webp";
import papa1 from "../images/einschlafbegleitung/einschlafbegleitung-schlafgut-schlafberatung-papa-1-min.webp";
import mama1 from "../images/einschlafbegleitung/einschlafbegleitung-schlafgut-schlafberatung-mama-schwanger-1-min.webp";
import familie2 from "../images/einschlafbegleitung/einschlafbegleitung-schlafgut-schlafberatung-familie-2-min.webp";
import baby3 from "../images/einschlafbegleitung/einschlafbegleitung-schlafgut-schlafberatung-baby-3-min.webp";
import backfliup from "../images/schlaft_back_light.jpg";
import SandyPalmer from "../images/sandy_palmer.jpg";

const Einschlafbegleitung = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const ref = useRef();

  let myheight = 470;
  if (isBrowser) myheight = window?.innerWidth < 400 ? 700 : 470;

  React.useEffect(() => {
    setTimeout(() => {
      document.getElementById("showSchlafAlert").classList.remove("hide");
      document.getElementById("showSchlafAlert").classList.add("show");
    }, 10000);
  }, []);

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Einschlafbegleitung" />
      <div className="row">
        <div
          className="col-sm-12 d-none d-sm-block"
          style={{ height: 40 }}
        ></div>
        <div
          className="col-sm-12 text-center"
          style={{ padding: 40, paddingBottom: 0, paddingTop: 0 }}
        >
          <h1>Schlafprobleme bei Babys und Kleinkindern liebevoll lösen</h1>
        </div>
        <div
          className="col-sm-12 d-none d-sm-block"
          style={{ height: 40 }}
        ></div>
        <div className="col-sm-6" style={{ padding: 40, paddingTop: 0 }}>
          <h2>
            Hallo liebe <strong>Mama</strong> und lieber <strong>Papa</strong>,
          </h2>
          <p>
            Du bist auf der Seite{" "}
            <a
              href="https://www.einschlafbegleitung.de"
              title="www.einschlafbegleitung.de"
            >
              www.einschlafbegleitung.de
            </a>{" "}
            gelandet, weil du dich fragst, wie du dein Kind{" "}
            <strong>friedlich</strong> und <strong>ruhig</strong> in den Schlaf{" "}
            <strong>begleiten</strong> kannst.
          </p>
        </div>
        <div
          className="col-sm-6"
          style={{
            backgroundImage: `url(${baby1})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: 200,
          }}
        ></div>
        <div
          className="col-sm-6"
          style={{
            backgroundImage: `url(${baby2})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="col-sm-6" style={{ padding: 40 }}>
          <h2>Tipps für eure Einschlafbegleitung</h2>
          <p>
            Dafür gibt es einige Ideen und im Internet wirst du sicher auch
            fündig, was dir Inspiration und Tipps für eure{" "}
            <strong>Einschlafbegleitung</strong> geben kann.
          </p>
          <p>
            Doch ist dieser Tipp oder der beschriebene Ablauf von anderen
            Familien auch passend für deine eigene Familie?!
            <br />
            In einer{" "}
            <a
              href="https://schlafgut-schlafberatung.de/schlafberatung/"
              target="_blank"
              title="persönlichen Schlafberatung"
            >
              persönlichen Schlafberatung
            </a>{" "}
            erarbeite ich mit euch Eltern die optimale{" "}
            <a
              href="https://schlafgut-schlafberatung.de/schlafberatung/#Was-ist-ein-Schritt-f%C3%BCr-Schritt-Plan"
              target="_blank"
              title="Was ist ein Schritt für Schritt Plan"
            >
              Einschlafbegleitung
            </a>{" "}
            für dich und dein Kind und deine Familie. Denn JEDE Familie ist
            anders, einzigartig, individuell und hat unterschiedliche
            Bedürfnisse... dies beachtet der eine Tipp aus dem Internet leider
            nicht.
          </p>
        </div>
        <div
          className="d-xs-block d-sm-none col-12"
          style={{
            backgroundImage: `url(${papa1})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: 200,
          }}
        ></div>
        <div className="col-sm-6" style={{ padding: 40 }}>
          <h2>Liebevolle Begleitung für dein Kind</h2>
          <p>
            Wenn du also an einer <strong>Begleitung für dein Kind</strong>{" "}
            interessiert bist, welche genau zu deinem Kind und deiner Familie
            passt, dann melde dich gerne bei mir!
          </p>
          <p>
            Hier findest du meine Internetseite:{" "}
            <a
              href="https://schlafgut-schlafberatung.de"
              title="www.schlafgut-schlafberatung.de"
            >
              www.schlafgut-schlafberatung.de
            </a>
          </p>
        </div>
        <div
          className="col-sm-6"
          style={{
            backgroundImage: `url(${familie1})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: 200,
          }}
        ></div>
        <div
          className="col-sm-6"
          style={{
            backgroundImage: `url(${papa1})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="col-sm-6" style={{ padding: 40 }}>
          <h2>Themen der Schlafberatung </h2>
          <strong>
            In meiner individuellen Schlafberatung werden Themen bearbeitet wie:
          </strong>
          <ul style={{ marginTop: 20 }}>
            <li>
              Wie kann ich verlässliche Tagschläfchen in meinen Alltag
              integrieren?
            </li>
            <li>
              Wie können wir ruhigere Nächte haben? (weil du weniger oft
              Aufstehen / stillen / Flasche geben musst)
            </li>
            <li>
              Wie können die Schlafphasen meines Kindes in der Nacht länger
              werden?
            </li>
            <li>Wie kann mein Kind entspannt ein- und durchschlafen?</li>
            <li>
              Einschlafhilfen (Einschlafstillen / Tragen / Wippen / Kinderwagen
              / Geräusche) möchte ich minimieren oder nicht mehr
            </li>
            <li>Hilfe, mein Kind lässt sich nicht ablegen!</li>
            <li>
              Einführung von Einschlafritualen, die auf dein Kind abgestimmt
              sind
            </li>
            <li>Ruhige und entspannte Einschlafbegleitung für Mama UND Papa</li>
            <li>Umzug ins eigene Kinderbett oder Kinderzimmer</li>
            <li>Mehr Ruhephasen und Erholungsphasen für euch Eltern</li>
            <li>Wie kann ich gelassener in die Einschlafsituationen gehen?</li>
            <li>
              Ich habe schon so viel probiert, es scheint nichts zu klappen!
            </li>
          </ul>
        </div>
        <div
          className="col-sm-12 d-none d-sm-block"
          style={{ height: 40 }}
        ></div>
        <div className="col-md-4" style={{ padding: 30 }}>
          <Flippy
            flipOnHover={true}
            flipOnClick={true}
            flipDirection="horizontal"
            ref={ref}
            style={{ height: myheight }}
          >
            <FrontSide
              style={{
                backgroundImage: `url(${mama1})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="d-flex justify-content-center align-items-end"
            >
              <Button style={{ height: "auto" }}>
                Mein Kind ist unterwegs
              </Button>
            </FrontSide>
            <BackSide
              style={{
                backgroundImage: `url(${backfliup})`,
                backgroundSize: "cover",
                minHeight: myheight,
                height: "auto",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <p>
                <br />
                Wenn dein Kind noch im Bauch ist, dann kannst du mein
                Beratungsangebot erhalten und wir machen euch fit für das
                bevorstehende erste Babyjahr. Du erhältst kurz und kompakt alles
                Wissenswerte, was du rund ums Thema Babyschlaf beachten
                solltest.
                <br />
                <br />
                <Button
                  target="_blank"
                  href="https://schlafgut-schlafberatung.de/schlafpaket/sternenhimmel"
                  style={{ height: "auto" }}
                >
                  Schlafpaket Sternenhimmel
                </Button>
              </p>
            </BackSide>
          </Flippy>
        </div>
        <div className="col-md-4" style={{ padding: 30 }}>
          <Flippy
            flipOnHover={true}
            flipOnClick={true}
            flipDirection="horizontal"
            ref={ref}
            style={{ height: myheight }}
          >
            <FrontSide
              style={{
                backgroundImage: `url(${familie2})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="d-flex justify-content-center align-items-end"
            >
              <Button style={{ height: "auto" }}>
                Mein Kind ist 0-5 Monate alt
              </Button>
            </FrontSide>
            <BackSide
              style={{
                backgroundImage: `url(${backfliup})`,
                backgroundSize: "cover",
                minHeight: myheight,
                height: "auto",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <p>
                <br />
                Wenn dein Baby bereits auf der Welt ist und noch keine 6 Monate
                alt ist, kannst du ebenfalls das Schlafcoaching in Anspruch
                nehmen. Dann klären wir eure persönlichen Fragen zum Thema
                Schlaf und Schlafverhalten deines Kindes.
                <br />
                Auch Themen wie Rituale zum Einschlafen und Schlafenszeiten
                gehören dazu.
                <br />
                <br />
                <Button
                  target="_blank"
                  href="https://schlafgut-schlafberatung.de/schlafpaket/mondschein"
                  style={{ height: "auto" }}
                >
                  Schlafpaket Mondschein
                </Button>
              </p>
            </BackSide>
          </Flippy>
        </div>
        <div className="col-md-4" style={{ padding: 30 }}>
          <Flippy
            flipOnHover={true}
            flipOnClick={true}
            flipDirection="horizontal"
            ref={ref}
            style={{ height: myheight }}
          >
            <FrontSide
              style={{
                backgroundImage: `url(${baby3})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              className="d-flex justify-content-center align-items-end"
            >
              <Button style={{ height: "auto" }}>
                Mein Kind ist 6 - 36 Monate alt
              </Button>
            </FrontSide>
            <BackSide
              style={{
                backgroundImage: `url(${backfliup})`,
                backgroundSize: "cover",
                minHeight: myheight,
                height: "auto",
              }}
              className="d-flex justify-content-center align-items-center"
            >
              <p>
                <br />
                Das Schlafcoaching für deine Familie biete ich für Eltern von
                Kindern im Alter von 6-36 Monaten an.
                <br />
                Routinen und Struktur sind Anhaltspunkte für Kinder, an denen
                sie sich orientieren können. Diese Orientierung gibt deinem Kind
                Sicherheit und schafft Vertrauen. Wie du diese in deinen Alltag
                integrieren kannst, erarbeite ich gerne mit euch gemeinsam. So
                könnt ihr einen liebevollen und friedlichen Rahmen schaffen, in
                dem sich dein Kind bewegen darf.
                <br />
                <br />
                <Button
                  target="_blank"
                  href="https://schlafgut-schlafberatung.de/schlafpaket/schlafgut"
                  style={{ height: "auto" }}
                >
                  Schlafpaket Schlafgut
                </Button>
              </p>
            </BackSide>
          </Flippy>
        </div>
        <div className="col-sm-12 text-center" style={{ padding: 40 }}>
          <p>
            Hier gibts mehr Informationen zur Schlafberatung für Babys und
            Kleinkinder:
            <br />
            👉{" "}
            <a
              href="https://www.schlafgut-schlafberatung.de"
              title="www.schlafgut-schlafberatung.de"
              target="_blank"
            >
              www.schlafgut-schlafberatung.de
            </a>
          </p>
        </div>
        <div className="col-sm-12" style={{ padding: 40, marginTop: -40 }}>
          <div className="row">
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-4 text-end">E-Mail: </div>
                <div className="col-8">
                  <a
                    href="mailto:hallo@schlafgut-schlafberatung.de"
                    target="_blank"
                    title="E-Mail"
                  >
                    hallo(at)schlafgut-schlafberatung.de
                  </a>
                </div>
                <div className="col-4 text-end">Telefon: </div>
                <div className="col-8">
                  <a href="tel:+491776256528" target="_blank" title="Telefon">
                    +49 177 6256528
                  </a>
                </div>
                <div className="col-4 text-end">Instagram: </div>
                <div className="col-8">
                  <a
                    href="https://www.instagram.com/schlafgut_schlafberatung"
                    target="_blank"
                    title="Instagram"
                  >
                    schlafgut_schlafberatung
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <div
        style={{
          position: "fixed",
          top: 20,
          left: 20,
          right: 20,
          backgroundColor: "#152e5e",
          color: "#fff",
        }}
        id="showSchlafAlert"
        className="alert alert-primary alert-dismissible fade hide"
        role="alert"
      >
        <div className="row">
          <div className="col-auto">
            <img
              src={SandyPalmer}
              alt="Sandy Palmer"
              width="60"
              height="60"
              className="rounded-circle"
            />
          </div>
          <div
            className="col d-flex align-items-center"
            style={{ fontSize: 17 }}
          >
            <p style={{ margin: 0 }}>
              {new Date().getHours() > 18
                ? "Guten Abend. "
                : new Date().getHours() < 12 && "Guten Morgen. "}{" "}
              Melde dich für ein&nbsp;
              <a
                href="https://schlafgut-schlafberatung.de/kontakt/"
                target="_blank"
                style={{ color: "#fff" }}
              >
                <strong>kostenloses Erstgespräch</strong>
              </a>
              . Alle 1:1 Coachings sind online möglich! Ich freue mich auf euch.
            </p>
          </div>
        </div>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          style={{
            filter: "invert(100%)",
          }}
          onClick={() => {
            document.getElementById("showSchlafAlert").classList.remove("show");
            document.getElementById("showSchlafAlert").classList.add("hide");
          }}
        ></button>
      </div>
    </Layout>
  );
};

export default Einschlafbegleitung;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
